<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 21:14
 @Version: 1.0
 Description: 普通用户和权限用户修改密码界面
-->
<template>
  <div class="update-password-header">
    <span>书籍管理系统</span>
  </div>
  <div class="update-password-form">
    <el-form
        ref="form"
        style="max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中，如果需要的话 */
        padding-top: 14%;
        "
        :model="form"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm">

      <el-form-item prop="username" style="width: 60%;">
        <el-input placeholder="请输入账号" v-model="form.username"/>
      </el-form-item>

      <el-form-item prop="oldPassword" style="width: 60%;">
        <el-input v-model="form.oldPassword" placeholder="请输入旧密码" type="password"/>
      </el-form-item>

      <el-form-item prop="password" style="width: 60%;">
        <el-input v-model="form.password" placeholder="请输入新密码" type="password"/>
      </el-form-item>

      <el-form-item prop="confirmPassword" style="width: 60%;">
        <el-input v-model="form.confirmPassword" placeholder="请输入确认密码" type="password"/>
      </el-form-item>
      <div style="display: flex">
        <el-form-item prop="validateCode" style="width: 30%;height: 40px;margin-left: 80px ; padding-right: 20px">
          <el-input v-model="form.validateCode" placeholder="请输入验证码"/>
        </el-form-item>
        <captcha @update-captcha="updateCaptcha"></captcha>
      </div>


    </el-form>
    <el-button style="width: 180px;position: absolute;bottom:40px;left:26%" type="primary" @click="updateUser">修改
    </el-button>
  </div>
</template>

<script>
import Captcha from "@/components/currency/Captcha.vue";
import store from "@/store";
import request from "@/utils/request";
import router from "@/router";

export default {
  name: 'userAndPowerUpdatePassword',
  computed: {
    store() {
      return store
    }
  },
  components: {Captcha},
  data() {
    return {
      form: {},
      validateCode: '',
      imgPath: 'url(' + require('@/assets/background.jpg') + ')',
      url: require('@/assets/default.jpg'),
      captchaText: '',
      rules: {
        username: [{
          required: true, message: "请输入账号", trigger: 'blur'
        }, {
          pattern: /^\d{9}$/, message: "账号必须等于9位数", trigger: 'blur'
        }],
        password: [{
          required: true, message: "请输入密码", trigger: 'blur'
        }, {
          pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/,
          message: "密码包括大小字母及数字且长度大于10",
          trigger: 'blur'
        }],
        oldPassword: [{
          required: true, message: "请输入之前使用的密码", trigger: 'blur'
        }, {
          pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/,
          message: "密码包括大小字母及数字且长度大于10",
          trigger: 'blur'
        }],
        confirmPassword: [
          {required: true, message: "请输入确认密码", trigger: 'blur'},
          {validator: this.validateConfirmPassword, trigger: 'blur'}
        ],
        validateCode: [{required: true, message: "请输入验证码", trigger: 'blur'},
          {
            validator: this.validateCaptcha,
            trigger: 'blur'
          }]
      },

    }
  },
  methods: {
    //修改验证码
    updateCaptcha(text) {
      //将传递过来的验证码赋值给captchaText
      this.captchaText = text;
    },
    // 验证验证码是否正确
    validateCaptcha(rule, value, callback) {
      // 将用户输入的验证码和生成的验证码文本都转换为小写形式进行比较
      if (value.toLowerCase() !== this.captchaText.toLowerCase()) {
        callback(new Error('验证码不正确'));
      } else {
        callback();
      }
    },
    // 验证确认密码是否和第一次输入的密码相同
    validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请确认密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },
    updateUser() {

      this.$refs['form'].validate(validate => {

        if (validate) {

          // 判断用户修改的是否是当前登录的用户
          if (this.form.username === store.getters.getLoginUserName) {

            request.put("/data/user/updateUser", {
              userName: this.form.username,
              oldPassword: this.form.oldPassword,
              password: this.form.password,
              onlineUserName:store.getters.getLoginUserName
            }).then(result => {

              if (result && result.code === 200) {

                this.$message({
                  type: "success",
                  message: result.message
                })

                //清空登录数据
                store.commit('setPower', 0);
                store.commit('setLoginFlag', false);
                store.commit('setLoginUserName', '');

                request.delete("/data/user/clear").then(result => {

                  if (result && result.code === 200) {

                    //跳转到登录界面
                    router.push("/login");

                  } else {
                    this.$message({
                      type: "error",
                      message: "退出登录失败"
                    })
                  }

                }).catch(error => {

                  this.$message({
                    type: "error",
                    message: error
                  })


                })


              } else if (result && result.code === 404) {
                this.$message({
                  type: "error",
                  message: result.message
                })

              }


            }).catch(error => {
              this.$message({
                type: "error",
                message: error
              })
            })


          } else {
            this.$message({
              type: "error",
              message: "请输入当前登录的用户账号"
            })

          }
        }
      })
    }
  },
  mounted() {
    document.body.style.backgroundImage = this.imgPath;
  },
  beforeUnmount() {
    document.body.style.backgroundImage = '';
  },
  created() {
    this.form.username = store.getters.getLoginUserName;
  }
}
</script>

<style>
.update-password-form {
  height: 400px;
  width: 400px;
  margin: 0 auto;
  border-radius: 10%;
  background-color: #ecf5ff;

  opacity: 0.7;
  position: relative;
}

.update-password-header {
  width: 300px;
  margin: 0 auto;
  margin-top: 5%;
  padding: 10px;
  text-align: center;
}

.update-password-header span {
  font-size: 45px;
  font-family: SimSun;
  font-weight: bold;
  color: #031B4E;
}


</style>