<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 8:57
 @Version: 1.0
 Description: 通用头部组件
-->
<template>
  <div id="header" >

    <div class="header-left" style="flex: 4;">
      <span v-if="store.getters.getPower === 0">书籍管理系统-普通用户</span>
      <span v-else-if="store.getters.getPower === 3">书籍管理系统-权限用户</span>
    </div>



    <div  class="header-right-manage" style="flex: 1;margin-left: 20px">

        <el-dropdown trigger="click" style="margin-top: 16px" >
        <span class="el-dropdown-link">
             <el-icon style="vertical-align: middle; "  :size="25">
                <Avatar/>
            </el-icon >
        </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Sunset">
                <span @click="clearLogin">
                  退出登录
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

      <el-button style="margin-left: 50px;margin-top: 10px" type="primary" @click="higherLevel" text>返回上级</el-button>
      <el-button style="margin-left: -10px;margin-top: 10px" type="primary" @click="toMainPage" text>返回主页</el-button>
    </div>
  </div>

</template>

<script>
import {Avatar, Sunset,} from "@element-plus/icons-vue";
import store from "@/store";
import request from "@/utils/request";
import router from "@/router";

  export default {
    name:'header',
    components: {Avatar},
    computed: {
      store() {
        return store
      },
      Sunset() {
        return Sunset
      },
    },
    methods:{
      clearLogin(){

        store.commit('setLoginFlag',false);

        store.commit('setLoginUserName','');

        store.commit('setPower',0);

        request.delete("/data/user/clear").then(result => {

          if(result && result.code === 200){

            this.$message({
              type: "success",
              message: result.message
            })

           router.push('/');

          }else {
            this.$message({
              type: "error",
              message: "退出登录失败"
            })
          }

        })

      },
      toMainPage(){

        router.push('/')
      },
      higherLevel(){
        const url = store.getters.getPreviousUrl;

        router.push(`${url}`);
      }
    }
  }
</script>

<style>

</style>