<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 8:52
 @Version: 1.0
 Description: 书架页面
-->
<template>
  <div class="header-user-manage" style="flex: 1;">
    <Header/>
  </div>

  <div class="user-manage-body" style="display: flex;justify-content: center;
  align-items: center;">
    <div class="body-right-view">
      <BookShelfTable/>
    </div>
  </div>

</template>

<script>
import Header from "@/components/currency/Header.vue";
import {Timer} from "@element-plus/icons-vue";
import BookShelfTable from "@/components/user/BookShelfTable.vue";

export default {
  name: 'bookShelfPage',
  components: {
    BookShelfTable,
    Timer,
    Header,
  },
  data() {
    return {
      userBackground: 'url(' + require('@/assets/userbackground.jpg') + ')',

    }
  },

  mounted() {
    // 在组件挂载时对 body 元素进行设置
    this.addBodyStyles();

  },
  beforeUnmount() {
    // 在组件卸载时清理 body 元素的设置
    this.removeBodyStyles();
  },
  methods: {
    addBodyStyles() {
      // 记录原始的 body 样式
      this.originalBodyStyle = {
        backgroundImage: document.body.style.backgroundImage,
        position: document.body.style.position,
        height: document.body.style.height,
        overflow: document.body.style.overflow,
      };

      // 创建一个 <style> 元素并添加 body 和 body::before 的样式
      const style = document.createElement('style');

      style.innerHTML = `
        body.custom-background {
          background-image: none !important;
          position: relative !important;
          height: 100vh !important;
          overflow: hidden !important;
        }
        body.custom-background::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: ${this.userBackground} !important;
          background-size: cover !important;
          background-position: center !important;
          opacity: 0.5 !important;
          pointer-events: none !important;
        }
      `;
      style.setAttribute('id', 'customBodyStyle');
      document.head.appendChild(style);

      // 给 body 添加自定义类
      document.body.classList.add('custom-background');
    },
    removeBodyStyles() {
      // 移除自定义类
      document.body.classList.remove('custom-background');

      // 恢复原始的 body 样式
      document.body.style.backgroundImage = this.originalBodyStyle.backgroundImage;
      document.body.style.position = this.originalBodyStyle.position;
      document.body.style.height = this.originalBodyStyle.height;
      document.body.style.overflow = this.originalBodyStyle.overflow;

      // 移除 <style> 元素
      const style = document.getElementById('customBodyStyle');
      if (style) {
        document.head.removeChild(style);
      }
    }
  }
}
</script>

<style>
</style>
