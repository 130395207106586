import {createRouter, createWebHistory} from 'vue-router'
import MainPage from "@/components/MainPage.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import GeneralUserManagePage from "@/views/user/GeneralUserManagePage.vue";
import UserBorrowBookTable from "@/components/user/UserBorrowBookTable.vue";
import BookShelfPage from "@/views/BookShelfPage.vue";
import PowerUserManagePage from "@/views/power/PowerUserManagePage.vue";
import PowerBookDisplayTable from "@/components/power/PowerBookDisplayTable.vue";

import UserAndPowerUpdatePassword from "@/views/UserAndPowerUpdatePassword.vue";
import AdministratorLogin from "@/views/administrator/AdministratorLogin.vue";
import AdministratorManage from "@/views/administrator/AdministratorManage.vue";
import AdministratorBookDisplayTable from "@/components/manage/AdministratorBookDisplayTable.vue";
import FeedbackBoxPage from "@/views/FeedbackBoxPage.vue";
import BorrowBookTable from "@/components/power/PowerBorrowBookTable.vue";
import ManageUpdateBookTable from "@/components/handlebook/ManageUpdateBookTable.vue";
import ManageInsertBookTable from "@/components/manage/ManageInsertBookTable.vue";
import ManageEmpowerUserTable from "@/components/manage/ManageEmpowerUserTable.vue";
import ResetPassword from "@/views/administrator/ResetPassword.vue";
import FeedbackBoxTable from "@/components/manage/FeedbackBoxTable.vue";
import UserBorrowBookViewTable from "@/components/handlebook/UserBorrowBookViewTable.vue";
import ManageBorrowBookTable from "@/components/manage/ManageBorrowBookTable.vue";
import store from "@/store";
import CheckFeedbackBoxPage from "@/views/administrator/CheckFeedbackBoxPage.vue";


const routes = [
    {
        path: '/', //主界面
        name: 'MainPage',
        component: MainPage,
    },
    {
        path: '/login', //登录界面
        name: 'Login',
        component: Login,
    },
    {
        path: '/register',//注册界面
        name: 'Register',
        component: Register
    },
    {
        path: '/user/generalUserManage',//普通用户管理路由
        name: 'GeneralUserManage',
        component: GeneralUserManagePage,
        meta: {
            requiresAuth: true // 添加一个元信息，表示需要进行身份验证
        },
        children: [{
            path: 'borrowBookTable',//用户借书一览表
            name: 'UserBorrowBookTable',
            component: UserBorrowBookTable
        },]
    }, {
        path: '/bookShelf',//书架
        name: 'BookShelfPage',
        component: BookShelfPage,
        meta: {
            requiresAuth: true // 添加一个元信息，表示需要进行身份验证
        }
    },
    {
        path: '/power/powerUserManage',//权限用户管理路由
        name: 'PowerUserManage',
        component: PowerUserManagePage,
        meta: {
            requiresAuth: true // 添加一个元信息，表示需要进行身份验证
        },
        children: [{
            path: 'powerBookDisplayTable', //权限用户图书一览表
            name: 'PowerBookDisplayTable',
            component: PowerBookDisplayTable
        }, {
            path: 'powerBorrowBookTable', //普通用户借书一览表
            name: 'PowerBorrowBookTable',
            component: BorrowBookTable
        },
            {
                path: 'powerUpdateBookTable',//修改图书
                name: 'PowerUpdateBookTable',
                component: ManageUpdateBookTable
            }, {
                path: 'powerUserBorrowBook',//用户详细借书表
                name: 'PowerUserBorrowBook',
                component: UserBorrowBookViewTable
            }
        ]
    }, {
        path: '/updatePassword',//普通用户和权限用户修改密码
        name: 'UserAndPowerUpdatePassword',
        component: UserAndPowerUpdatePassword,
        meta: {
            requiresAuth: true // 添加一个元信息，表示需要进行身份验证
        }
    },
    {
        path: '/administrator/login',//管理员登录
        name: 'AdministratorLogin',
        component: AdministratorLogin
    }, {
        path: '/administrator/manage',//管理员管理路由
        name: 'AdministratorManage',
        component: AdministratorManage,
        meta: {
            requiresAuth: true // 添加一个元信息，表示需要进行身份验证
        },
        children: [{
            path: 'manageBorrowBookTable',//管理员用户借书一览表
            name: 'ManageBorrowBookTable',
            component: ManageBorrowBookTable
        }, {
            path: 'manageUpdateBookTable',//管理员修改呢图书
            name: 'ManageUpdateBookTable',
            component: ManageUpdateBookTable
        }, {
            path: 'manageBookDisplayTable',//管理员查看书籍详细列表
            name: 'AdministratorBookDisplay',
            component: AdministratorBookDisplayTable
        }, {
            path: 'insertBook',//添加图书
            name: 'ManageInsertBookTable',
            component: ManageInsertBookTable
        }, {
            path: 'empower',//授权表
            name: 'ManageEmpowerUserTable',
            component: ManageEmpowerUserTable
        }, {
            path: 'feedbackBoxTable',//反馈邮箱表
            name: 'FeedbackBoxTable',
            component: FeedbackBoxTable
        }, {
            path: 'manageUserBorrowBook',//用户详细借书表
            name: 'ManageUserBorrowBook',
            component: UserBorrowBookViewTable
        },
        ],

    },
    {
        path: '/feedback',//反馈界面
        name: 'FeedbackBox',
        component: FeedbackBoxPage
    },
    {
        path: '/checkFeedbackBoxPage',//用户详细借书表
        name: 'CheckFeedbackBoxPage',
        component: CheckFeedbackBoxPage
    }
    ,{
        path: '/administrator/resetPassword',//管理员修改密码页面
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            requiresAuth: true // 添加一个元信息，表示需要进行身份验证
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


// 全局导航守卫
router.beforeEach((to, from, next) => {
    //获取是否是以登录状态
    const isLoggedIn = store.getters.getLoginFlag;
    //获取管理员是否登录
    const isAdministratorLoggedIn = store.getters.getAdministratorFlag;
    //获取当前请求的路径
    const currentPath = to.path;

    store.commit('setPreviousUrl',from.fullPath);

    //判断是否是需要验证的路由
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //判断是哪些路由
        if (currentPath.includes('/user/generalUserManage') ||
            currentPath.includes('/bookShelf') || currentPath.includes('/power/powerUserManage')) {
            // 判断是否需要登录
            if (!isLoggedIn) {
                next({
                    path: '/login',
                });
            } else {
                next();
            }

        } else if (currentPath.includes('/administrator/manage') ||
            currentPath.includes('/administrator/resetPassword')) {

            // 判断是否需要登录
            if (!isAdministratorLoggedIn) {
                next({
                    path: '/administrator/login',
                });
            } else {
                next();
            }

        } else {
            next();
        }


    } else {
        //放行
        next();
    }
});


export default router
