<!--
 @Author: leon--ZGJ
 @Create: 2024/6/7
 @Version: 1.0
 Description: 主页面
-->
<template>

  <div id="header">

    <div class="header-left" style="flex: 4">
      <span>书籍管理系统</span>
    </div>

    <div class="header-right-search" style="align-items: center; flex: 1.2">

      <el-input
          v-model="condition"
          style="width: 240px;"
          placeholder="请输入关键字"
      />
      <el-button slot="suffix" @click="list" :icon="Search"></el-button>
    </div>


    <div class="header-right-manage" style=" flex:0.8;margin-left: 20px">
      <nav>

        <el-dropdown trigger="click" style="margin-top: 16px" v-if="login_validate">
        <span class="el-dropdown-link">
             <el-icon style="vertical-align: middle; " :size="25">
                <Avatar/>
            </el-icon>
        </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Sunset">
                <span @click="clearLogin">
                   退出登录
                </span>
              </el-dropdown-item>

              <el-dropdown-item :icon="User">
                <span @click="toUserManage">
                   个人主页
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>

          </template>
        </el-dropdown>

        <el-dropdown trigger="click" style="margin-top: 16px" v-else>
        <span class="el-dropdown-link">
             <el-icon style="vertical-align: middle; " :size="25">
                <Avatar/>
            </el-icon>
        </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="User">
                <router-link style="text-decoration: none;color: black" class="link" to="/login">
                  普通用户登录
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item :icon="UserFilled">
                <router-link style="text-decoration: none;color: black" class="link"
                             to="/power/powerUserManage/powerBookDisplayTable">
                  管理员用户登录
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <router-link class="link" to="/bookShelf">
          <el-badge :value="bookNumber" class="item">
            <el-icon style="margin-left: 10px;vertical-align: middle;text-decoration: none;" color="black" :size="25">
              <Notebook/>
            </el-icon>
          </el-badge>
        </router-link>

      </nav>

    </div>
  </div>

  <div style=" height: 50px; ">
<!--    <el-menu-->
<!--        style="display: flex;-->
<!--        justify-content: space-around;-->
<!--        align-items: center;-->
<!--        font-size: 16px;-->
<!--        border-bottom: none;-->
<!--        font-weight: bold;"-->
<!--        :default-active="defaultActive"-->
<!--        class="el-menu-demo"-->
<!--        mode="horizontal"-->
<!--        @select="handleSelect"-->
<!--        background-color="#F5F7FA"-->
<!--        ref="menu"-->
<!--    >-->
<!--      <el-menu-item index="1" @click="navigationDate('')">全类图书</el-menu-item>-->
<!--      <el-sub-menu index="2">-->
<!--        <template #title>文理类图书</template>-->
<!--        <el-menu-item index="2-1" @click="navigationDate('文学类')">文学类图书</el-menu-item>-->
<!--        <el-menu-item index="2-2" @click="navigationDate('理工类')">理工类图书</el-menu-item>-->
<!--        <el-menu-item index="2-3" @click="navigationDate('医学类')">医学类图书</el-menu-item>-->
<!--        <el-sub-menu index="2-4">-->
<!--          <template #title>国文类图书</template>-->
<!--          <el-menu-item index="2-4-1" @click="navigationDate('词典类')">词典类图书</el-menu-item>-->
<!--          <el-menu-item index="2-4-2" @click="navigationDate('文言文类')">文言文类图书</el-menu-item>-->
<!--          <el-menu-item index="2-4-3" @click="navigationDate('词文断句类')">词文断句类图书</el-menu-item>-->
<!--        </el-sub-menu>-->
<!--      </el-sub-menu>-->
<!--      <el-menu-item index="3" @click="navigationDate('其他类')">其他类图书</el-menu-item>-->
<!--    </el-menu>-->
  </div>

  <div class="view" style="flex: auto">

    <div class="book-view" v-for="book in bookList">
      <el-image style="width: 200px; height: 200px" :src="require('@/assets/default.jpg')"/>
      <span>书名：{{ book.bookName }}</span>
      <span>作者：{{ book.author }}</span>
      <span>科类：{{ book.category }}</span>
      <span>借阅：<span class="span-number">{{ book.borrowNumber }}</span></span>
      <span>库存：<span class="span-number">{{ book.stock }}</span></span>
      <el-button type="primary" @click="addBookToBookshelf(book)" plain>加入书架</el-button>
    </div>

  </div>


  <el-pagination style="
      justify-content: center;
      align-items: center; "
                 v-model:current-page="currentPage"
                 v-model:page-size="pageSize"
                 :page-sizes="[3,4, 5]"
                 :disabled="disabled"
                 :background="background_pagination"
                 layout="total,sizes,prev, pager, next, jumper"
                 :total="total"
                 @size-change="handleSizeChange"
                 @current-change="handleCurrentChange"
  />


</template>

<script>

import {Avatar, Notebook, Search, Sunset, User, UserFilled} from '@element-plus/icons-vue'
import {ref} from "vue";
import request from "@/utils/request";
import store from "@/store";
import router from "@/router";


export default {
  name: 'mainPage',
  components: {Avatar, Notebook,},
  computed: {
    Sunset() {
      return Sunset
    },
    Search() {
      return Search
    },
    UserFilled() {
      return UserFilled
    },
    User() {
      return User
    }
  },
  data() {
    return {
      pageSize: 5,
      currentPage: 1,
      total: 100,
      disabled: false,
      background_pagination: true,
      condition: ref(''),
      bookNumber: 0,
      defaultActive: ref('1'),
      background: '#ecf5ff',
      bookList: [],
      login_validate: store.getters.getLoginFlag,
      category: '',
      url: 'url(' + require('@/assets/userbackground.jpg') + ')'
    }
  },
  methods: {
    //key表示当前的选择的分组的index
    handleSelect(index, indexPath, item) {

    },
    handleSizeChange(pageSize) {
      //将变化的显示数赋值给数据池中的pageSize
      this.pageSize = pageSize;
      // 调用查询方法
      this.list();

    },
    handleCurrentChange(currentPage) {
      //将变化的显示数赋值给数据池中的pageSize
      this.currentPage = currentPage;
      // 调用查询方法
      this.list();
    },
    list() {
      request.get('/data/book/conditionPagingByBookName', {
        params: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
          condition: this.condition
        }
      }).then(result => {
        

        if (result.code === 200) {

          this.bookList = result.extend.pageInfo.list;
          this.total = result.extend.pageInfo.total;
        } else if (result.code === 404) {
          this.$message({
            type: "error",
            message: "查询失败"
          })
        }

      })
    },
    addBookToBookshelf(book) {
      //判断用户是否登录
      if (this.login_validate) {
        //发出请求
        request.post("/data/bookshelf/insertBookshelf/" + book.bookId, {
          bookName: book.bookName,
          author: book.author,
          category: book.category,
          borrowNumber: 1,
          userName: store.getters.getLoginUserName
        }).then(result => {
          //判断是否存在
          if (result && result.code === 200) {
            // 书架数量加一
            this.bookNumber++;
            // 显示添加成功
            this.$message({
              type: "success",
              message: result.message
            })

          } else if (result && result.code === 404) {
            // 显示添加成功
            this.$message({
              type: "success",
              message: result.message
            })
          }


        })

      } else {
        // 显示添加成功
        this.$message({
          type: "error",
          message: "添加失败，请您登录后添加"
        })
      }
    },
    navigationDate(category) {

      this.category = category;

      request.get("/data/book/conditionPagingByCategory", {
        params: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
          category: this.category
        }
      }).then(result => {
        if (result.code === 200) {
          this.bookList = result.extend.pageInfo.list;
          this.total = result.extend.pageInfo.total;
        } else if (result.code === 404) {
          this.$message({
            type: "error",
            message: "查询失败"
          })
        }
      })
    },
    selectBorrowNumber() {

      if (store.getters.getLoginFlag) {
        request.get("/data/bookshelf/selectTotalBorrowNumber", {
          params: {
            userName: store.getters.getLoginUserName
          }
        }).then(result => {
          if (result && result.code === 200) {
            this.bookNumber = result.extend.borrowNumber;
          } else if (result && result.code === 404) {
            this.bookNumber = 0;
          }
        }).catch(error => {
          this.$message({
            type: "error",
            message: "请求失败: " + error.message
          });
        })
      }

    },
    clearLogin() {

      store.commit('setLoginFlag', false);

      store.commit('setLoginUserName', '');

      store.commit('setPower', 0);

      request.delete("/data/user/clear").then(result => {

        if (result && result.code === 200) {

          this.$message({
            type: "success",
            message: result.message
          })

          window.location.reload(); // 刷新页面

        } else {
          this.$message({
            type: "error",
            message: "退出登录失败"
          })
        }

      })

    },
    toUserManage() {
      //判断用户是否登录
      if (store.getters.getLoginFlag) {
        //跳转到管理界面
        router.push("/user/generalUserManage/borrowBookTable");

      }


    },
    addBodyStyles() {
      // 记录原始的 body 样式
      this.originalBodyStyle = {
        backgroundImage: document.body.style.backgroundImage,
        position: document.body.style.position,
        height: document.body.style.height,
        overflow: document.body.style.overflow,
      };

      // 创建一个 <style> 元素并添加 body 和 body::before 的样式
      const style = document.createElement('style');

      style.innerHTML = `
        body.custom-background {
          background-image: none !important;
          position: relative !important;
          height: 100vh !important;
          overflow: hidden !important;
        }
        body.custom-background::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: ${this.url} !important;
          background-size: cover !important;
          background-position: center !important;
          opacity: 0.5 !important;
          pointer-events: none !important;
        }
      `;
      style.setAttribute('id', 'customBodyStyle');
      document.head.appendChild(style);

      // 给 body 添加自定义类
      document.body.classList.add('custom-background');
    },
    removeBodyStyles() {
      // 移除自定义类
      document.body.classList.remove('custom-background');

      // 恢复原始的 body 样式
      document.body.style.backgroundImage = this.originalBodyStyle.backgroundImage;
      document.body.style.position = this.originalBodyStyle.position;
      document.body.style.height = this.originalBodyStyle.height;
      document.body.style.overflow = this.originalBodyStyle.overflow;

      // 移除 <style> 元素
      const style = document.getElementById('customBodyStyle');
      if (style) {
        document.head.removeChild(style);
      }
    }
  },
  mounted() {
    // 在组件挂载时对 body 元素进行设置
    this.addBodyStyles();

  },
  beforeUnmount() {
    // 在组件卸载时清理 body 元素的设置
    this.removeBodyStyles();
  },
  created() {
    this.list();
    this.selectBorrowNumber();
  },
}

</script>


<style>
body {
  margin: 0;
  padding: 0;
}

#header {

  height: 80px;
  line-height: 50px;
  padding-top: 50px;
  display: flex;
  align-items: center; /* 垂直居中对齐 */;
}

.header-left {
  margin-bottom: 2px;
  width: auto;
  padding-left: 30px;
  font-weight: bold;
  color: #031B4E;
}

.header-left span {
  font-size: 40px;
  margin: auto;
}


.view {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  margin-right: 50px;
}

.book-view {
  float: left;
  margin-left: 0;
  padding-left: 50px;
  width: 200px;
  height: 400px;

}

.book-view span {
  display: flex;
  align-items: center;
  font-family: SimSun;
  font-weight: bold;
  font-style: italic;
}

.span-number {
  color: #F56C6C;
  font-size: 20px;
}

/* 使用深度选择器覆盖el-pagination中的el-select组件的样式 */
::v-deep .el-pagination__sizes .el-input__inner {
  height: 40px; /* 设置显示框高度 */
  line-height: 40px; /* 设置行高 */
  padding: 0 10px; /* 设置内边距 */
  font-size: 16px; /* 设置字体大小 */
  width: 40px;

}
.link{
  color: black;
}

</style>