<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 20:46
 @Version: 1.0
 Description: 书籍展示，后面操作不一样
-->
<template>
  <div class="body-right-view" style="flex: 5;margin-top: 60px;margin-right: -200px">
    <el-table :data="tableData" class="custom-table" style="width: 1250px">
      <el-table-column label="录入时间" width="200">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon>
              <timer/>
            </el-icon>
            <span style="margin-left: 10px">{{ formatDate(scope.row.storageTime) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="书名" width="150">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.bookName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="作者" width="100">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.author }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类别" width="150">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.category }}</span>
        </template>
      </el-table-column>
      <el-table-column label="借阅" width="100">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.borrowNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="库存" width="100">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.stock }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改" width="100">
        <template #default="scope">
          <span style="margin-left: 10px" v-if="scope.row.modify == 0">未修改</span>
          <span style="margin-left: 10px" v-if="scope.row.modify == 1">已修改</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间" width="200">
        <template #default="scope">
          <span style="margin-left: 10px">{{ formatDate(scope.row.modifyTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small" type="danger" @click="toUpdateBook(scope.row)">
            修改
          </el-button>
          <el-button size="small" type="danger" @click="toDeleteBook(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <div style="display: flex;margin-top: 50px;margin-left: 200px;
      justify-content: center;
      align-items: center;
     ">
    <el-pagination style=""
                   v-model:current-page="currentPage"
                   v-model:page-size="pageSize"
                   :disabled="disabled"
                   :background="background"
                   layout="total,prev, pager, next, jumper"
                   :total="total"
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
    />
  </div>

</template>

<script>
import {Timer} from "@element-plus/icons-vue";
import request from "@/utils/request";
import {format} from "date-fns";
import router from "@/router";
import store from "@/store";
import {ElMessageBox} from "element-plus";

export default {
  name: 'powerBookDisplayTable',
  components: {Timer},
  data() {
    return {
      currentPage: 1,
      pageSize: 4,
      disabled: false,
      background: true,
      total: 100,
      tableData: [],
    }
  },
  methods: {
    list() {
      request.get("/data/book/selectAllBook", {
        params: {
          pageSize: this.pageSize,
          currentPage: this.currentPage
        }
      }).then(result => {

        if (result && result.code === 200) {

          this.tableData = result.extend.pageInfo.list;
          this.total = result.extend.pageInfo.total;


        } else if (result && result.code === 404) {
          this.$message({
            type: "error",
            message: result.message
          })
        }

      }).catch(error => {
        this.$message({
          type: "error",
          message: error
        })

      })
    },
    //日期格式化
    formatDate(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    handleSizeChange(pageSize) {

      this.pageSize = pageSize;

      this.list();

    },
    handleCurrentChange(currentPage) {

      this.currentPage = currentPage;

      this.list();
    },
    toUpdateBook(row){

      ElMessageBox.confirm(
          '',
          {
            title:'修改',
            message:'您确认是需要修改吗？',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
            center: true,
            icon: 'EditPen',
            customStyle:{width:"200px",}
          }
      ).then(() => {

        store.commit('setBook',row);

        router.push({name:'ManageUpdateBookTable'})

      }).catch(() =>{})



    },
    toDeleteBook(row){

      ElMessageBox.confirm(
         '',
          {
            title:'删除',
            message:'您确认是需要删除吗？',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
            center: true,
            icon: 'DeleteFilled',
            customStyle:{width:"200px",}
          }
      ).then(() => {

        request.delete('/data/book/deleteBookById', {params:{bookId:row.bookId}}).then(result => {

          if(result && result.code === 200){
              this.$message({
                type: "success",
                message: result.message
              })

            this.list();

          }else if(result && result.code === 404){
            this.$message({
              type: "error",
              message: result.message
            })
          }

        }).catch(error => {

          this.$message({
            type: "error",
            message: error
          })
        })

      }).catch(() =>{})



    }
  },
  created() {
    this.list();
  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>