<!--
 @Author: leon--ZGJ
 @Create: 2024/6/7
 @Version: 1.0
 Description: root用户管理
-->
<template>
  <div class="manage-header-user-manage" style="flex: 1;padding-top: 40px">
    <div id="header">

      <div class="header-left" style="flex: 4">
        <span>书籍管理系统</span>
      </div>



      <div  class="header-right-manage" style="flex: 0.8;margin-left: 20px">

          <el-dropdown trigger="click" style="margin-top: 16px" >
        <span class="el-dropdown-link">
             <el-icon style="vertical-align: middle; "  :size="25">
                <Avatar/>
            </el-icon >
        </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="Sunset">
                  <span @click="clearLogin">
                    退出登录
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

        <el-button style="margin-left: 20px;margin-top: 10px" type="primary" @click="higherLevel" text>返回上级</el-button>
        <el-button style="margin-left: -10px;margin-top: 10px" type="primary" @click="toMainPage" text>返回主页</el-button>

      </div>
    </div>

  </div>
  <div class="manage-user-manage-body" style="display: flex;justify-content: center;
  align-items: center;">
    <div class="body-left-side" style="margin-left:-120px;flex: 0.27;">
      <AdministratorManageSide/>
    </div>
    <div>
      <router-view/>
    </div>
  </div>

</template>

<script>
import Header from "@/components/currency/Header.vue";
import AdministratorManageSide from "@/components/manage/AdministratorManageSide.vue";
import {Avatar, Sunset} from "@element-plus/icons-vue";
import {Store} from "vuex";
import store from "@/store";
import request from "@/utils/request";
import router from "@/router";


export default {
  name: 'administratorManage',
  computed: {
    Sunset() {
      return Sunset
    }
  },
  components: {Avatar, AdministratorManageSide, Header},
  data() {
    return {
      userBackground: 'url(' + require('@/assets/userbackground.jpg') + ')',

    }
  },

  mounted() {
    // 在组件挂载时对 body 元素进行设置
    this.addBodyStyles();
    // // 在组件挂载时自动导航到默认子路由
    // if (this.$route.path === '/user/usermanage') {
    //   this.$router.push({name: 'UserTable'});
    // }

  },
  beforeUnmount() {
    // 在组件卸载时清理 body 元素的设置
    this.removeBodyStyles();
  },
  methods: {
    addBodyStyles() {
      // 记录原始的 body 样式
      this.originalBodyStyle = {
        backgroundImage: document.body.style.backgroundImage,
        position: document.body.style.position,
        height: document.body.style.height,
        overflow: document.body.style.overflow,
      };

      // 创建一个 <style> 元素并添加 body 和 body::before 的样式
      const style = document.createElement('style');

      style.innerHTML = `
        body.custom-background {
          background-image: none !important;
          position: relative !important;
          height: 100vh !important;
          overflow: hidden !important;
        }
        body.custom-background::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: ${this.userBackground} !important;
          background-size: cover !important;
          background-position: center !important;
          opacity: 0.5 !important;
          pointer-events: none !important;
        }
      `;
      style.setAttribute('id', 'customBodyStyle');
      document.head.appendChild(style);

      // 给 body 添加自定义类
      document.body.classList.add('custom-background');
    },
    removeBodyStyles() {
      // 移除自定义类
      document.body.classList.remove('custom-background');

      // 恢复原始的 body 样式
      document.body.style.backgroundImage = this.originalBodyStyle.backgroundImage;
      document.body.style.position = this.originalBodyStyle.position;
      document.body.style.height = this.originalBodyStyle.height;
      document.body.style.overflow = this.originalBodyStyle.overflow;

      // 移除 <style> 元素
      const style = document.getElementById('customBodyStyle');
      if (style) {
        document.head.removeChild(style);
      }
    },
    clearLogin(){

      store.commit('setAdministratorFlag',false);

      request.delete("/data/root/clear").then(result => {

        if(result && result.code === 200){

          this.$message({
            type: "success",
            message: result.message
          })

          router.push('/');

        }else {
          this.$message({
            type: "error",
            message: "退出登录失败"
          })
        }

      })

    },
    toMainPage(){

      router.push('/')
    },
    higherLevel(){
      const url = store.getters.getPreviousUrl;

      router.push(`${url}`);
      
    }
  }
}
</script>

<style>
</style>