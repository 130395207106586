<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 21:14
 @Version: 1.0
 Description: 反馈箱页面
-->
<template>
  <div class="feedback-box-header">
    <span>书籍管理系统</span>
  </div>
  <div class="feedback-box-form">
    <div style="margin-left: 50px;padding-top: 55px">
      <el-input
          v-model="feedbackMessage"
          maxlength="200"
          style="width: 300px;align-items: center;justify-content: center;"
          :autosize="{ minRows: 10, maxRows: 12 }"
          type="textarea"
          show-word-limit
          placeholder="请输入反馈信息"
      />
    </div>
    <el-button style="width: 180px;position: absolute;bottom:40px;left:26%" type="primary" @click="commitFeedback">反馈</el-button>
  </div>
</template>

<script>

import request from "@/utils/request";
import store from "@/store";
import router from "@/router";

export default {
  name: 'feedbackBoxPage',
  data() {
    return {
      form: {},
      validateCode: '',
      imgPath: 'url(' + require('@/assets/background.jpg') + ')',
      feedbackMessage:'',
    }
  },
  methods:{
    commitFeedback(){

      request.post("/data/feedback/insertFeedback",{
          message:this.feedbackMessage,
          userName:store.getters.getLoginUserName
      }).then(result =>{

        if (result && result.code === 200){

          this.$message({
            type: "success",
            message: result.message
          })

          this.feedbackMessage = '';

          router.push({name:'UserBorrowBookTable'});

        }else  if(result && result.code === 404){
          this.$message({
            type: "error",
            message: result.message
          })
        }

      }).catch(error =>{
        this.$message({
          type: "error",
          message: error
        })
      })

    }
  },
  mounted() {
    document.body.style.backgroundImage = this.imgPath;
  },
  beforeUnmount() {
    document.body.style.backgroundImage = '';
  }
}
</script>

<style>
.feedback-box-form {
  height: 400px;
  width: 400px;
  margin: 0 auto;
  border-radius: 10%;
  background-color: #ecf5ff;
  opacity: 0.7;
  position: relative;
}

.feedback-box-header {
  width: 300px;
  margin: 0 auto;
  margin-top: 5%;
  padding: 10px;
  text-align: center;
}

.feedback-box-header span {
  font-size: 45px;
  font-family: SimSun;
  font-weight: bold;
  color: #031B4E;
}


</style>