<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 10:04
 @Version: 1.0
 Description: 普通用户管理侧边栏
-->
<template>
  <div>
    <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
      <el-radio-button :value="false">展开</el-radio-button>
      <el-radio-button :value="true">关闭</el-radio-button>
    </el-radio-group>
    <el-menu
        default-active="3"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        @open="handleOpen"
        @close="handleClose"
    >
      <el-sub-menu index="1">
        <template #title>
          <el-icon>
            <Setting/>
          </el-icon>
          <span>用户管理</span>
        </template>

        <el-menu-item index="1-1">
          <el-icon>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/updatePassword">
                <RefreshLeft/>
              </router-link>
            </nav>
          </el-icon>
          <template #title>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/updatePassword">修改密码</router-link>
            </nav>
          </template>
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item index="2">
        <el-icon>
          <nav>
            <router-link style="text-decoration: none" class="link" to="/bookShelf">
              <Notebook/>
            </router-link>
          </nav>
        </el-icon>
        <template #title>
          <nav>
            <router-link style="text-decoration: none" class="link" to="/bookShelf">书架</router-link>
          </nav>
        </template>
      </el-menu-item>
      <el-menu-item index="3">
        <el-icon>
          <nav>
            <router-link style="text-decoration: none" class="link" to="/user/generalUserManage/borrowBookTable">
              <Reading/>
            </router-link>
          </nav>
        </el-icon>
        <template #title>
          <nav>
            <router-link style="text-decoration: none" class="link" to="/user/generalUserManage/borrowBookTable">借阅一览</router-link>
          </nav>
        </template>
      </el-menu-item>
      <el-menu-item index="4">
        <el-icon>
          <nav>
            <router-link style="text-decoration: none" class="link" to="/feedback">
              <ChatLineRound/>
            </router-link>
          </nav>
        </el-icon>

        <template #title>
          <nav>
            <router-link style="text-decoration: none" class="link" to="/feedback">反馈箱</router-link>
          </nav>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {ref} from "vue";
import {ChatLineRound, Notebook, Reading, RefreshLeft, Setting} from "@element-plus/icons-vue";

export default {
  name: 'generalManageSide',
  components: {ChatLineRound, Reading, Notebook, RefreshLeft, Setting},
  data() {
    return {
      isCollapse: ref(true)
    }
  }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 200px;
}
</style>