<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 14:09
 @Version: 1.0
 Description: 反馈箱表格
-->
<template>
  <div class="body-right-view" style="flex: 9;margin-top: 60px">
    <el-table :data="tableData" class="custom-table" style="width: 900px">
      <el-table-column label="反馈时间" width="200" label-align="center">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon>
              <timer/>
            </el-icon>
            <span style="margin-left: 10px">{{ formatDate(scope.row.feedbackTime) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="反馈者" width="150" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈信息" width="400" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.message}}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" label-align="center">
        <template #default="scope">
          <el-button
              style="text-decoration: none;color: #FFFFFF"
              size="small"
              type="danger"
              @click="checkFeedbackMessage(scope.row.message)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div style="display: flex;margin-top: 50px;
      justify-content: center;
      align-items: center; ">
    <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :disabled="disabled"
        :background="background"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {Timer} from "@element-plus/icons-vue";
import request from "@/utils/request";
import {format} from "date-fns";
import router from "@/router";
import store from "@/store";

export default {
  name:'feedbackBoxTable',
  components: {Timer},
  data(){
    return{
      currentPage: 1,
      pageSize: 4,
      disabled: false,
      background: true,
      total: '',
      tableData: [],
    }
  },
  methods:{
    list(){

      request.get('/data/feedback/selectAllFeedback',{
        params:{
          pageSize:this.pageSize,
          currentPage:this.currentPage
        }
      }).then(result =>{

        this.tableData = result.extend.pageInfo.list;
        this.total = result.extend.pageInfo.total;

      }).catch(error =>{
        this.$message({
          type: "error",
          message: error
        })
      })

    },
    handleSizeChange(pageSize){
      this.pageSize = pageSize;
      this.list();
    },
    handleCurrentChange(currentPage){
      this.currentPage = currentPage;
      this.list();
    },
    //日期格式化
    formatDate(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    checkFeedbackMessage(message){

      store.commit('setFeedbackMessage',message)

      router.push('/checkFeedbackBoxPage');

    }
  },
  created() {
    this.list();
  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>