<!--
 @Author: leon--ZGJ
 @Create: 2024/6/7
 @Version: 1.0
 Description: 登录界面
-->
<template>


  <div class="login-header">
    <span>书籍管理系统</span>
  </div>
  <div class="login-form">
    <el-form
        ref="form"
        style="max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中，如果需要的话 */
        padding-top: 12%;
        "
        :model="form"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm"
    >
      <el-form-item prop="username" style="width: 60%;">
        <el-input placeholder="请输入账号" v-model="form.username"/>
      </el-form-item>
      <el-form-item prop="password" style="width: 60%;">
        <el-input v-model="form.password" placeholder="请输入密码" type="password"/>
      </el-form-item>
    </el-form>
    <nav>

      <span style="margin-left:80px;padding-right: 130px;font-size: 13px"><router-link style="text-decoration: none"
                                                                                       class="link"
                                                                                       to="/register">账号注册</router-link></span>
      <span style="font-size: 13px"><router-link style="text-decoration: none" class="link"
                                                 to="/register">忘记密码?</router-link></span>
    </nav>
    <el-button style="width: 180px;position: absolute;top:250px;left:26%" type="primary" @click="login">
      登录
    </el-button>

  </div>

</template>

<script>


import request from "@/utils/request";
import store from "@/store";
import router from "@/router";

export default {
  name: 'login',
  data() {
    return {
      form: {},
      rules: {
        username: [{
          required: true, message: "请输入账号", trigger: 'blur'
        }, {
          pattern: /^\d{9}$/, message: "账号必须等于9位数", trigger: 'blur'
        }],
        password: [{
          required: true, message: "请输入密码", trigger: 'blur'
        }, {
          // ^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$
          pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/,
          message: "密码包括大小字母及数字且长度大于10",
          trigger: 'blur'
        }]
      },
      imgPath: 'url(' + require('@/assets/background.jpg') + ')'
    }

  },
  methods: {
    login() {

      this.$refs['form'].validate(validate => {

        if (validate) {
          request.get("/data/user/validateUser/" + this.form.username).then(result => {


            if (result && result.code === 200) {
              //账号存在则再次发出Ajax请求
              return request.get("/data/user/validateUserByUser", {
                params: {
                  username: this.form.username,
                  password: this.form.password
                }
              })
            } else if (result && result.code === 404) {
              this.$message({
                type: "error",
                message: result.message
              })

            }
          }).then(twoResult => {

            if (twoResult && twoResult.code === 200) {
              //获取权限值
              const power =  twoResult.extend.power;
              //设置成登录了
              store.commit('setLoginFlag',true);
              //设置登录用户名称
              store.commit('setLoginUserName',this.form.username);
              //设置权限
              store.commit('setPower',power);
              //判断权限值然后实现跳转
              if(power === 3){//权限用户
                //页面跳转
                router.push("/power/powerUserManage/powerBookDisplayTable")
              }else if(power === 0){//普通用户
                //跳转页面
                router.push("/user/generalUserManage/borrowBookTable");
              }


            } else if (twoResult && twoResult.code === 404) {
              this.$message({
                type: "error",
                message: twoResult.message
              })


            }

          })

        }

      })
    }
  },
  mounted() {
    document.body.style.backgroundImage = this.imgPath;
  },
  beforeUnmount() {
    document.body.style.backgroundImage = '';
  }
}

</script>


<style>

.login-form {
  height: 200px;
  width: 400px;
  margin: 0 auto;
  border-radius: 12%;
  background-color: #ecf5ff;

  opacity: 0.6;
  position: relative;
}

.login-header {
  width: 300px;
  margin: 0 auto;
  margin-top: 10%;
  padding: 10px;
  text-align: center;
}

.login-header span {
  font-size: 45px;
  font-family: SimSun;
  font-weight: bold;
  color: #031B4E;
}


</style>