import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入element-plus
import ElementPlus from 'element-plus'
//引入主要样式
import 'element-plus/dist/index.css'

//引入icon图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const  app = createApp(App);


app.use(store);
app.use(router);
app.use(ElementPlus);


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}




app.mount('#app');
