<!--
 @Author: leon--ZGJ
 @Create: 2024/6/7
 @Version: 1.0
 Description:
-->
<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <el-config-provider :locale="locale">
  <router-view/>
  </el-config-provider>
</template>

<script>

import { ElConfigProvider } from 'element-plus'

import zhCn from 'element-plus/es/locale/lang/zh-cn'

export default {
  name:'LayOut',
  components:{
    ElConfigProvider
  },
  setup(){
    return {
      locale: zhCn,
    }
  }

}

</script>

<style>
</style>
