<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 14:09
 @Version: 1.0
 Description: 查看用户借书信息
-->
<template>
  <div class="body-right-view" style="flex: 9;margin-top: 60px;margin-left: -20px">
    <el-table :data="tableData" class="custom-table" style="width: 500px">
      <el-table-column label="借阅时间" width="200" label-align="center">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon>
              <timer/>
            </el-icon>
            <span style="margin-left: 10px">{{ formatDate(scope.row.borrowTime)}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="借阅数量" width="100" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.number }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户" width="200" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <div style="display: flex;margin-top: 50px;margin-left: -20px;
      justify-content: center;
      align-items: center; ">
    <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :disabled="disabled"
        :background="background"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {Timer} from "@element-plus/icons-vue";
import store from "@/store";
import request from "@/utils/request";
import {format} from "date-fns";

export default {
  name: 'userBorrowBookViewTable',
  components: {Timer},
  data() {
    return {
      currentPage: 1,
      pageSize: 4,
      disabled: false,
      background: true,
      total: 0,
      borrowBook:{},
      tableData: [],
    }
  },
  methods: {
    handleCurrentChange(currentPage){

      this.currentPage = currentPage;

      this.list();
    },
    handleSizeChange(pageSize){

      this.pageSize = pageSize;

      this.list();

    },
    getBorrowBook(){

      const borrowBook = store.getters.getBorrowBook;

      if (borrowBook) {

        this.borrowBook = borrowBook;

      }

    },
    list(){

        if(this.borrowBook){

            request.get("/data/borrow/selectBorrowUser",{
              params:{
                bookName:this.borrowBook.bookName,
                author:this.borrowBook.author,
                category:this.borrowBook.category,
                pageSize:this.pageSize,
                currentPage:this.currentPage
              }
            }).then(result =>{

              if(result && result.code === 200){
                this.tableData = result.extend.pageInfo.list;
                this.total = result.extend.pageInfo.total;
                

              }else if(result && result.code === 404){
                this.$message({
                  type: "error",
                  message: result.message
                })
              }


            }).catch(error => {
              this.$message({
                type: "error",
                message: error
              })
            })

        }

    },
    //日期格式化
    formatDate(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
  },
  created() {
    this.getBorrowBook();
    this.list();
  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>