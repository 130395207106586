import { createStore } from 'vuex'

export default createStore({
  state: {
    // 从 sessionStorage 获取 loginFlag 并解析，如果没有则默认为 false
    longinFlag:JSON.parse(sessionStorage.getItem('loginFlag')) || false,
    // 从 sessionStorage 获取 administratorFlag 并解析，如果没有则默认为 false
    administratorFlag:JSON.parse(sessionStorage.getItem('administratorFlag')) || false,
    // 从 sessionStorage 获取 loginUserName 并解析，如果没有则默认为 ''
    loginUserName:JSON.parse(sessionStorage.getItem('loginUserName')) || '',
    // 从 sessionStorage 获取 power 并解析，如果没有则默认为 0
    power:JSON.parse(sessionStorage.getItem('power')) || 0,
    // 从 sessionStorage 获取 book 并解析，如果没有则默认为 {}
    book:JSON.parse(sessionStorage.getItem('book')) || {},
    // 从 sessionStorage 获取 borrowBook 并解析，如果没有则默认为 {}
    borrowBook:JSON.parse(sessionStorage.getItem('borrowBook')) || {},
    // 从 sessionStorage 获取 feedbackMessage 并解析，如果没有则默认为 {}
    feedbackMessage:JSON.parse(sessionStorage.getItem('feedbackMessage')) || '',
    previousUrl: '',
  },
  getters: {
    getLoginFlag(state){
      //返回结果
      return state.longinFlag;
    },
    getAdministratorFlag(state){
      //返回结果
      return state.administratorFlag ;
    } ,
    getLoginUserName(state){
      //返回结果
      return state.loginUserName ;
    },
    getPower(state){
      //返回结果
      return state.power ;
    },
    getBook(state){
      return state.book;
    },
    getBorrowBook(state){
      return state.borrowBook;
    },
    getFeedbackMessage(state){
      return state.feedbackMessage;
    },
    getPreviousUrl(state){
      return state.previousUrl;
    },
  },
  mutations: {
    setLoginFlag(state,flag){
      //设置状态
      state.longinFlag = flag ;
      //将结果保存在会话中
      sessionStorage.setItem("loginFlag",JSON.stringify(flag));

    },
    setAdministratorFlag(state,flag){
      //设置状态
      state.administratorFlag = flag ;

      //将结果保存在会话中
      sessionStorage.setItem("administratorFlag",JSON.stringify(flag));
    },
    setLoginUserName(state,flag){
      //设置状态
      state.loginUserName = flag ;

      //将结果保存在会话中
      sessionStorage.setItem("loginUserName",JSON.stringify(flag));
    },
    setPower(state,flag){
      //设置状态
      state.power = flag ;

      //将结果保存在会话中
      sessionStorage.setItem("power",JSON.stringify(flag));
    },
    setBook(state,flag){
      //设置状态
      state.book = flag;

      //将结果保存在会话中
      sessionStorage.setItem("book",JSON.stringify(flag));

    },
    setBorrowBook(state,flag){

      state.borrowBook = flag;

      //将结果保存在会话中
      sessionStorage.setItem("borrowBook",JSON.stringify(flag));

    },
    setFeedbackMessage(state,flag){

      state.feedbackMessage = flag;

      //将结果保存在会话中
      sessionStorage.setItem("feedbackMessage",JSON.stringify(flag));

    },
    setPreviousUrl(state, url) {
      state.previousUrl = url
    }
  },
  actions: {
    setLoginFlag({commit}){
      commit('setLoginFlag')
    },
    setAdministratorFlag({commit}){
      commit('setAdministratorFlag')
    },
    setLoginUserName({commit}){
      commit('setLoginUserName')
    },
    setPower({commit}){
      commit('setPower')
    },
    setBook({commit}){
      commit('setBook')
    },
    setBorrowBook({commit}){
      commit('setBorrowBook')
    },
    setFeedbackMessage({commit}){
      commit('setFeedbackMessage')
    },
    setPreviousUrl({commit}){
      commit('setPreviousUrl')
    }
  },
  modules: {
  }
})
