<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 16:28
 @Version: 1.0
 Description: 用来修改图书的
-->
<template>
  <div class="body-right-view" style="flex: 9;margin-top: 60px">
    <el-table :data="[tableData]" class="custom-table" style="width: 1000px">
      <el-table-column label="图片" width="150">
        <template #default="scope">
          <el-image style="width: 60px; height: 60px" :src="url"/>
        </template>
      </el-table-column>
      <el-table-column label="书名" width="150">
        <el-input v-model="tableData.bookName" style="width: 100px"/>
      </el-table-column>
      <el-table-column label="作者" width="150">
        <el-input v-model="tableData.author" style="width: 100px"/>
      </el-table-column>
      <el-table-column label="科类" width="150">
        <el-input v-model="tableData.category" style="width: 100px"/>
      </el-table-column>
      <el-table-column label="借阅" width="150">
        <el-input v-model="tableData.borrowNumber" style="width: 100px"/>
      </el-table-column>
      <el-table-column label="库存" width="150">
        <el-input clearable v-model="tableData.stock" style="width: 100px"/>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button style="text-decoration: none;color: #FFFFFF"
                     size="small"
                     type="danger"
                     @click="updateBook"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import store from "@/store";
import request from "@/utils/request";
import router from "@/router";
import {ElMessageBox} from "element-plus";

export default {
  name: 'manageUpdateBookTable',
  data() {
    return {
      url: require("@/assets/default.jpg"),
      tableData: {}
    }
  }, methods: {
    updateBook() {

      ElMessageBox.confirm(
          '',
          {
            title: '修改',
            message: '您确认是需要修改吗？',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
            center: true,
            icon: 'EditPen',
            customStyle: {width: "200px",}
          }
      ).then(() => {

        request.put("/data/book/updateBook", this.tableData).then(result => {

          if (result && result.code === 200) {

            this.$message({
              type: "success",
              message: result.message
            })
            //将数据清零
            store.commit('setBook', {});

            if (store.getters.getLoginFlag) {

              router.push({name: 'PowerBookDisplayTable'})

            } else if (store.getters.getAdministratorFlag) {
              router.push({name: 'AdministratorBookDisplay'})
            }


          } else if (result && result.code === 404) {

            this.$message({
              type: "error",
              message: result.message
            })


          }

        }).catch(error => {

          this.$message({
            type: "error",
            message: error
          })

        })


      }).catch(() => {
      })


    }
  },
  created() {
    const book = store.getters.getBook;
    if (book) {
      this.tableData = book;
    }


  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>