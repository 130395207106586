<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 14:09
 @Version: 1.0
 Description: 用户借阅展示
-->
<template>
  <div class="body-right-view" style="flex: 9;margin-top: 60px">
    <el-table :data="tableData" class="custom-table" style="width: 900px">
      <el-table-column label="借阅时间" width="200">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon>
              <timer/>
            </el-icon>
            <span style="margin-left: 10px">{{ formatDate(scope.row.borrowTime) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="书名" width="150">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.bookName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="作者" width="150">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.author }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类别" width="150">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.category }}</span>
        </template>
      </el-table-column>
      <el-table-column label="借阅数" width="150">
        <template #default="scope">
          <el-input-number style="width: 100px;margin-left: 10px" v-model="scope.row.number" size="small"/>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
              size="small"
              type="danger"
              @click="returningBooks(scope.row)">
            还书
          </el-button>
        </template>
      </el-table-column>
    </el-table>

  </div>
  <div style="display: flex;margin-top: 50px;
      justify-content: center;
      align-items: center;
     ">
    <el-pagination style=""
                   v-model:current-page="currentPage"
                   v-model:page-size="pageSize"
                   :disabled="disabled"
                   :page-sizes="[3,4,5]"
                   :background="background"
                   layout="total,sizes,prev, pager, next, jumper"
                   :total="total"
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
    />
  </div>

</template>

<script>
import {Timer} from "@element-plus/icons-vue";
import request from "@/utils/request";
import store from "@/store";
import {format} from "date-fns";
import {ElMessageBox} from "element-plus";
import router from "@/router";

export default {
  name: 'userBorrowBookTable',
  components: {Timer},
  data() {
    return {
      currentPage: 1,
      pageSize: 4,
      disabled: false,
      background: true,
      total: 100,
      tableData: [],
    }
  },
  methods: {
    handleSizeChange(pageSize) {

      this.pageSize = pageSize;

      this.list();

    },
    handleCurrentChange(currentPage) {

      this.currentPage = currentPage;

      this.list();

    },
    list() {

      request.get("/data/borrow/selectBorrow/" + store.getters.getLoginUserName, {
        params: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
        }
      }).then(result => {

        if (result && result.code === 200) {

          this.tableData = result.extend.pageInfo.list;
          this.total = result.extend.pageInfo.total;

        } else if (result && result.code === 404) {

          this.$message({
            type: "error",
            message: result.message
          })

        }

      })

    },
    //日期格式化
    formatDate(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    returningBooks(row) {

      ElMessageBox.confirm(
          '',
          {
            title:'还书',
            message:'您确认是需要还书吗？',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
            center: true,
            icon: 'Place',
            customStyle:{width:"200px",}
          }
      ).then(() => {

        request.put("/data/borrow/returningBooks", row).then(result => {

          if (result && result.code === 200) {

            this.$message({
              type: "success",
              message: result.message
            })

            //刷新界面
            window.location.reload();

          } else if (result && result.code === 404) {

            this.$message({
              type: "error",
              message: result.message
            })

          }


        }).catch(error => {
          this.$message({
            type: "error",
            message: error
          })
        })

      }).catch(() =>{})

    }
  },
  created() {
    this.list();
  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>