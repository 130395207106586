<!--
 @Author: leon--ZGJ
 @Create: 2024/6/7
 @Version: 1.0
 Description: root用户登录界面
-->
<template>


  <div class="administrator-login-header">
    <span>书籍管理系统</span>
  </div>
  <div class="administrator-login-form">
    <el-form
        ref="form"
        style="max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中，如果需要的话 */
        padding-top: 15%;
        "
        :model="form"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm"
    >
      <el-form-item prop="username" style="width: 60%;">
        <el-input placeholder="请输入账号" v-model="form.username"/>
      </el-form-item>
      <el-form-item prop="password" style="width: 60%;">
        <el-input v-model="form.password" placeholder="请输入密码" type="password"/>
      </el-form-item>
    </el-form>
    <el-button style="width: 180px;position: absolute;top:250px;left:26%" type="primary" @click="login">登录</el-button>
  </div>

</template>

<script>
import request from "@/utils/request";
import store from "@/store";
import router from "@/router";

export default {
  name: 'administratorLogin',
  data() {
    return {
      form: {},
      rules: {
        username: [{
          required: true, message: "请输入账号", trigger: 'blur'
        }, {
          pattern: /^.{9,}$/, message: "账号必须等于或大于9位数", trigger: 'blur'
        }],
        password: [{
          required: true, message: "请输入密码", trigger: 'blur'
        }, {
          // ^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$
          pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/,
          message: "密码需包含大小写字母及数字,且长度大于等于10位",
          trigger: 'blur'
        }]
      },
      imgPath: 'url(' + require('@/assets/background.jpg') + ')'
    }

  },
  methods: {
    login() {
      this.$refs['form'].validate(validate => {

        if (validate) {
          request.get("/data/root/validateRootName/", {
            params: {rootName: this.form.username}
          }).then(result => {

            if (result && result.code === 200) {
              //账号存在则再次发出Ajax请求
              return request.post("/data/root/validateRoot", {
                rootName: this.form.username,
                password: this.form.password
              });
            } else if (result &&  result.code === 404) {
              this.$message({
                type: "error",
                message: result.message
              })

            }
          }).then(twoResult => {

            if ( twoResult && twoResult.code === 200) {
              //设置权限
              store.commit('setAdministratorFlag', true);
              //跳转页面
              router.push("/administrator/manage/manageBookDisplayTable");

            } else if (twoResult && twoResult.code === 404) {
              this.$message({
                type: "error",
                message: twoResult.message
              })

            }

          })

        }

      })
    }
  },
  mounted() {
    document.body.style.backgroundImage = this.imgPath;
  },
  beforeUnmount() {
    document.body.style.backgroundImage = '';
  }
}

</script>


<style>

.administrator-login-form {
  height: 200px;
  width: 400px;
  margin: 0 auto;
  border-radius: 12%;
  background-color: #ecf5ff;

  opacity: 0.6;
  position: relative;
}

.administrator-login-header {
  width: 300px;
  margin: 0 auto;
  margin-top: 10%;
  padding: 10px;
  text-align: center;
}

.administrator-login-header span {
  font-size: 45px;
  font-family: SimSun;
  font-weight: bold;
  color: #031B4E;
}


</style>