<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 20:46
 @Version: 1.0
 Description: 书架
-->
<template>
  <div>
    <el-table :data="tableData" class="custom-table" style="width: 800px">
      <el-table-column label="图片" width="150">
        <template #default="scope">
          <el-image style="width: 60px; height: 60px" :src="url"/>
        </template>
      </el-table-column>
      <el-table-column label="书名" width="100">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.bookName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="作者" width="100">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.author }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类别" width="100">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.category }}</span>
        </template>
      </el-table-column>
      <el-table-column label="借阅数" width="150">
        <template #default="scope">
          <el-input-number style="width: 100px;margin-left: 10px" v-model="scope.row.borrowNumber" size="small"/>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small" type="danger" @click="borrowBook(scope.row)">
            借阅
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div style="display: flex;margin-top: 40px;
      justify-content: center;
      align-items: center;
     ">
    <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :disabled="disabled"
        :page-sizes="[2, 3, 5]"
        :background="background"
        layout="total,sizes,prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
  </div>

</template>

<script>
import {Timer} from "@element-plus/icons-vue";
import request from "@/utils/request";
import store from "@/store";
import {ElMessageBox} from "element-plus";
import router from "@/router";

export default {
  name: 'bookShelfTable',
  components: {Timer},
  data() {
    return {
      currentPage: 1,
      pageSize: 4,
      disabled: false,
      background: true,
      total: 100,
      tableData: [],
      url: require('@/assets/default.jpg')
    }
  },
  methods: {
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.list();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.list();
    },
    list() {
      request.get("/data/bookshelf/selectBookshelf/" + store.getters.getLoginUserName, {
        params: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
        }
      }).then(result => {

        if (result && result.code === 200) {

          this.tableData = result.extend.pageInfo.list;
          this.total = result.extend.pageInfo.total;

        } else if (result && result.code === 404) {
          this.$message({
            type: "error",
            message: result.message
          })
        }

      })


    },
    borrowBook(row) {


      ElMessageBox.confirm(
          '',
          {
            title: '借阅',
            message: '您确认是需要借书吗？',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
            center: true,
            icon: 'Promotion',
            customStyle: {width: "200px",}
          }
      ).then(() => {

        request.delete("/data/bookshelf/borrowBook", {data: row}).then(result => {

          if (result && result.code === 200) {

            this.$message({
              type: "success",
              message: result.message
            })
            //刷新界面
            window.location.reload();

          } else if (result && result.code === 404) {

            if (result.extend.stock != null) {

              this.$message({
                type: "error",
                message: result.message + "可借阅数" + result.extend.stock
              })

            } else {

              this.$message({
                type: "error",
                message: result.message
              })
            }


          }

        }).catch(error => {

          this.$message({
            type: "error",
            message: error
          })

        })


      }).catch(() => {
      })


    },

  },
  created() {
    this.list();
  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>