<!--
 @Author: leon--ZGJ
 @Create: 2024/6/12 9:56
 @Version: 1.0
 Description:
-->
<template>
  <div class="captcha-container">
    <!-- 验证码画布，当用户点击时会刷新验证码 -->
    <canvas ref="captchaCanvas"  :width="width" :height="height" @click="generateCaptcha"></canvas>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'Captcha',
  props: {
    width: {
      type: Number,
      default: 100 // 默认宽度为100像素
    },
    height: {
      type: Number,
      default: 40 // 默认高度为40像素
    },

  },
  emits: ['update-captcha'], // 定义一个事件，用于向父组件传递验证码文本
  setup(props, { emit }) {
    const captchaCanvas = ref(null); // 引用画布元素

    const generateCaptcha = () => {
      const canvas = captchaCanvas.value;
      const ctx = canvas.getContext('2d');
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // 验证码字符集
      let captchaText = '';

      // 清除画布
      ctx.clearRect(0, 0, props.width, props.height);

      // 绘制背景
      ctx.fillStyle = '#f3f3f3';
      ctx.fillRect(0, 0, props.width, props.height);

      // 绘制随机字符
      for (let i = 0; i < 6; i++) {
        const char = chars[Math.floor(Math.random() * chars.length)];
        captchaText += char;
        ctx.font = '24px Arial';
        ctx.fillStyle = '#333';
        ctx.fillText(char, 15 * i + 10, 30);
      }

      // 添加噪点和干扰线
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(Math.random() * props.width, Math.random() * props.height);
        ctx.lineTo(Math.random() * props.width, Math.random() * props.height);
        ctx.strokeStyle = '#ccc';
        ctx.stroke();
      }

      for (let i = 0; i < 30; i++) {
        ctx.beginPath();
        ctx.arc(Math.random() * props.width, Math.random() * props.height, 1, 0, 2 * Math.PI);
        ctx.fillStyle = '#ccc';
        ctx.fill();
      }

      // 向父组件传递验证码文本
      emit('update-captcha', captchaText);
    };

    // 组件挂载时生成一次验证码
    onMounted(() => {
      generateCaptcha();
    });

    return {
      captchaCanvas,
      generateCaptcha
    };
  }
};
</script>

<style scoped>
.captcha-container {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}

canvas {
  border: 1px solid #ccc;
  margin-right: 10px;
  cursor: pointer; /* 设置画布为可点击状态 */
}
</style>
