<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 14:09
 @Version: 1.0
 Description: 书籍借阅一览表，可以查看详细信息
-->
<template>
  <div class="body-right-view" style="flex: 9;margin-top: 60px">
    <el-table :data="tableData" class="custom-table" style="width: 1000px">
      <el-table-column label="入库时间" width="200" label-align="center">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon>
              <timer/>
            </el-icon>
            <span style="margin-left: 10px">{{ formatDate(scope.row.storageTime) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="书名" width="150" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.bookName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="作者" width="150" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.author }}</span>
        </template>
      </el-table-column>
      <el-table-column label="科类" width="150" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.category }}</span>
        </template>
      </el-table-column>
      <el-table-column label="库存" width="100" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.borrowNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column label="借阅" width="100" label-align="center">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.stock }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" label-align="center">
        <template #default="scope">
          <el-button
              style="text-decoration: none;color: #FFFFFF"
              size="small"
              type="danger"
              @click="selectBorrowUser(scope.row)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div style="display: flex;margin-top: 50px;
      justify-content: center;
      align-items: center;
     ">
    <el-pagination style=""
                   v-model:current-page="currentPage"
                   v-model:page-size="pageSize"
                   :disabled="disabled"
                   :page-sizes="[3,4,5]"
                   :background="background"
                   layout="total,sizes,prev, pager, next, jumper"
                   :total="total"
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {Timer} from "@element-plus/icons-vue";
import request from "@/utils/request";
import {format} from "date-fns";
import store from "@/store";
import router from "@/router";

export default {
  name: 'powerBorrowBookTable',
  components: {Timer},
  data() {
    return {
      currentPage: 1,
      pageSize: 4,
      disabled: false,
      background: true,
      total: 100,
      tableData: [],
    }
  },
  methods: {
    list() {

      request.get("/data/book/selectAllBook", {
        params: {
          pageSize: this.pageSize,
          currentPage: this.currentPage,
        }
      }).then(result => {

        if (result && result.code === 200) {

          this.tableData = result.extend.pageInfo.list;
          this.total = result.extend.pageInfo.total;

        } else if (result && result.code === 404) {

          this.$message({
            type: "error",
            message: result.message
          })

        }


      })

    },
    handleSizeChange(pageSize) {

      this.pageSize = pageSize;

      this.list();

    },
    handleCurrentChange(currentPage) {

      this.currentPage = currentPage;

      this.list();

    },
    //日期格式化
    formatDate(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    selectBorrowUser(row){
      
       //将数数据保存
       store.commit('setBorrowBook',row);

       router.push({name:'PowerUserBorrowBook'})

    }
  },
  created() {
    this.list();
  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>