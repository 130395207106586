<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 20:46
 @Version: 1.0
 Description: 用户授权表格
-->
<template>
  <div class="body-right-view" style="flex: 9;margin-top: 60px">
      <el-table :data="tableData" class="custom-table" style="width: 850px">
        <el-table-column label="注册时间" width="200">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer/>
              </el-icon>
              <span style="margin-left: 10px">{{ formatDate(scope.row.registerTime) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="账号" width="150">
          <template #default="scope">
            <span style="margin-left: 10px">{{ scope.row.userName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="电子邮箱" width="200">
          <template #default="scope">
            <span style="margin-left: 10px">{{ scope.row.email }}</span>
          </template>
        </el-table-column>
        <el-table-column label="权限" width="150">
          <template #default="scope">
              <el-input-number style="margin-top: 10px;margin-bottom: 10px" v-model="scope.row.power" size="small"/>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" type="danger" @click="empowerUser(scope.row)">
              授权
            </el-button>
            <el-button size="small" type="danger" @click="deleteUser(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
  </div>
  <div style="display: flex;margin-top: 50px;
      justify-content: center;
      align-items: center; ">
    <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :disabled="disabled"
        :background="background"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
  </div>

</template>

<script>
import {Timer} from "@element-plus/icons-vue";
import request from "@/utils/request";
import {format} from "date-fns";
import {ElMessageBox} from "element-plus";
import store from "@/store";
import router from "@/router";

export default {
  name: 'manageEmpowerUserTable',
  components: {Timer},
  data() {
    return {
      currentPage: 1,
      pageSize: 4,
      disabled: false,
      background: true,
      total: 100,
      tableData: [],
    }
  },
  methods: {
    list() {

      request.get("/data/user/selectAllUser").then(result => {

        this.tableData = result.extend.pageInfo.list;
        this.total = result.extend.pageInfo.total;


      }).catch(error => {

        this.$message({
          type: "error",
          message: error
        })

      })


    },
    handleSizeChange(pageSize) {

      this.pageSize = pageSize;

      this.list();

    },
    handleCurrentChange(currentPage) {

      this.currentPage = currentPage;

      this.list();

    },
    //日期格式化
    formatDate(date) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
    },
    empowerUser(row) {
      ElMessageBox.confirm(
          '',
          {
            title: '授权',
            message: '您确认是需要授权吗？',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
            center: true,
            icon: 'EditPen',
            customStyle: {width: "200px",}
          }
      ).then(() => {

        if(row.power === 0 || row.power === 3){

          request.put("/data/user/empowerUser", row).then(result => {

            if(result && result.code === 200){

              this.$message({
                type: "success",
                message: result.message
              })

              this.list();

            }else if(result && result.code === 404){

              this.$message({
                type: "error",
                message: result.message
              })

            }

          }).catch(error => {
            this.$message({
              type: "error",
              message: error
            })

          })
        }else {
          this.$message({
            type: "error",
            message: "权限值只能为0或3"
          })

          row.power = 0 ;
        }
      }).catch(() => {
      })

    },
    deleteUser(row) {
      ElMessageBox.confirm(
          '',
          {
            title: '删除',
            message: '您确认是需要删除吗？',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
            center: true,
            icon: 'EditPen',
            customStyle: {width: "200px",}
          }
      ).then(() => {

        request.delete("/data/user/deleteUserByUserName",{
          params:{
            userName:row.userName
          }
        }).then(result => {

          if(result && result.code === 200){
            this.$message({
              type: "success",
              message: result.message
            })
            this.list();
          }else if(result && result.code === 404){
            this.$message({
              type: "error",
              message: result.message
            })
          }



        }).catch(result => {
          this.$message({
            type: "error",
            message: error
          })
        })

      }).catch(() => {
      })
    }

  },
  created() {
    this.list();
  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>