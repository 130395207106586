<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 10:04
 @Version: 1.0
 Description: root用户管界面的侧边栏
-->
<template>
  <div>
    <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
      <el-radio-button :value="false">展开</el-radio-button>
      <el-radio-button :value="true">关闭</el-radio-button>
    </el-radio-group>
    <el-menu
        default-active="3"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        @open="handleOpen"
        @close="handleClose"
    >
      <el-sub-menu index="1">
        <template #title>
          <el-icon>
            <Setting/>
          </el-icon>
          <span>用户管理</span>
        </template>

        <el-menu-item index="1-1">
          <el-icon>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/resetPassword">
                <RefreshLeft/>
              </router-link>
            </nav>
          </el-icon>
          <template #title>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/resetPassword">修改密码</router-link>
            </nav>
          </template>
        </el-menu-item>
        <el-menu-item index="1-2">
          <el-icon>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/manage/empower">
                <PieChart/>
              </router-link>
            </nav>
          </el-icon>
          <template #title>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/manage/empower">用户授权</router-link>
            </nav>
          </template>
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="2">
        <template #title>
          <el-icon>
            <Notebook/>
          </el-icon>
          <span>图书操作</span>
        </template>

        <el-menu-item index="2-1">
          <el-icon>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/manage/manageBookDisplayTable">
                <Tickets/>
              </router-link>
            </nav>
          </el-icon>
          <template #title>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/manage/manageBookDisplayTable">修改图书</router-link>
            </nav>
          </template>
        </el-menu-item>
        <el-menu-item index="2-2">
          <el-icon>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/manage/insertBook">
                <CirclePlusFilled/>
              </router-link>
            </nav>
          </el-icon>
          <template #title>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/manage/insertBook">添加图书</router-link>
            </nav>
          </template>
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="3">
        <template #title>
          <el-icon>
            <Collection/>
          </el-icon>
          <span>图书管理</span>
        </template>

        <el-menu-item index="3-1">
          <el-icon>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/manage/manageBorrowBookTable">
                <Reading/>
              </router-link>
            </nav>
          </el-icon>
          <template #title>
            <nav>
              <router-link style="text-decoration: none" class="link" to="/administrator/manage/manageBorrowBookTable">借阅一览</router-link>
            </nav>
          </template>
        </el-menu-item>

      </el-sub-menu>
      <el-menu-item index="4">
        <el-icon>
          <nav>
            <router-link style="text-decoration: none" class="link" to="/administrator/manage/feedbackBoxTable">
              <ChatLineRound/>
            </router-link>
          </nav>
        </el-icon>
        <template #title>
          <nav>
            <router-link style="text-decoration: none" class="link" to="/administrator/manage/feedbackBoxTable">反馈箱</router-link>
          </nav>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {ref} from "vue";
import {
  ChatLineRound,
  CirclePlusFilled, Collection,
  Notebook,
  PieChart, Reading,
  RefreshLeft,
  Setting, Tickets

} from "@element-plus/icons-vue";

export default {
  name: 'administratorManageSide',
  components: {
    ChatLineRound,
    Reading,
    Collection,
    CirclePlusFilled,
    Tickets,
    Notebook,
    PieChart,
    RefreshLeft,
    Setting
  },
  data() {
    return {
      isCollapse: ref(true)
    }
  }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 200px;
}
</style>