<!--
 @Author: leon--ZGJ
 @Create: 2024/6/7
 @Version: 1.0
 Description: 注册界面
-->
<template>


  <div class="register-header">
    <span>书籍管理系统</span>
  </div>
  <div class="register-form">
    <el-form
        ref="form"
        style="max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中，如果需要的话 */
        padding-top: 14%;
        "
        :model="form"
        :rules="rules"
        label-width="auto"
        class="demo-ruleForm">

      <el-form-item prop="username" style="width: 60%;">
        <el-input placeholder="请输入账号" v-model="form.username"/>
      </el-form-item>

      <el-form-item prop="password" style="width: 60%;">
        <el-input v-model="form.password" placeholder="请输入密码" type="password"/>
      </el-form-item>

      <el-form-item prop="confirmPassword" style="width: 60%;">
        <el-input v-model="form.confirmPassword" placeholder="请再次输入确认密码" type="password"/>
      </el-form-item>

      <el-form-item prop="email" style="width: 60%;">
        <el-input v-model="form.email" placeholder="请输入邮箱"/>
      </el-form-item>
      <div style="display: flex">
        <el-form-item prop="validateCode" style="width: 30%;height: 40px;margin-left: 80px ; padding-right: 20px">
          <el-input v-model="form.validateCode" placeholder="请输入验证码"/>
        </el-form-item>
        <captcha @update-captcha="updateCaptcha"></captcha>
      </div>

    </el-form>
    <el-button style="width: 180px;position: absolute;bottom:40px;left:26%" type="primary" @click="register">注册
    </el-button>
  </div>

</template>

<script>


import Captcha from "@/components/currency/Captcha.vue";
import request from "@/utils/request";
import router from "@/router";

export default {
  name: 'register',
  components: {Captcha},
  data() {
    return {
      form: {},
      captchaText: '',
      userExist: false,
      rules: {
        username: [{
          required: true, message: "请输入账号", trigger: 'blur'
        }, {
          pattern: /^\d{9}$/, message: "账号必须等于9位数", trigger: 'blur'
        }, {
          validator: this.validateUserName, trigger: 'blur'
        }],
        password: [{
          required: true, message: "请输入密码", trigger: 'blur'
        }, {
          // ^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$
          pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/,
          message: "密码包括大小字母及数字且长度大于10",
          trigger: 'blur'
        }],
        confirmPassword: [
          {required: true, message: "请输入确认密码", trigger: 'blur'},
          {validator: this.validateConfirmPassword, trigger: 'blur'}
        ],
        email: [
          {required: true, message: "请输入邮箱", trigger: 'blur'},
          {
            //^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "请输入正确的电子邮件格式",
            trigger: 'blur'
          }
        ],
        validateCode: [{required: true, message: "请输入验证码", trigger: 'blur'},
          {
            validator: this.validateCaptcha,
            trigger: 'blur'
          }]
      },
      imgPath: 'url(' + require('@/assets/background.jpg') + ')',
      url: require("@/assets/default.jpg")
    }

  },
  methods: {
    //修改验证码
    updateCaptcha(text) {
      //将传递过来的验证码赋值给captchaText
      this.captchaText = text;
    },
    // 验证验证码是否正确
    validateCaptcha(rule, value, callback) {
      // 将用户输入的验证码和生成的验证码文本都转换为小写形式进行比较
      if (value.toLowerCase() !== this.captchaText.toLowerCase()) {
        callback(new Error('验证码不正确'));
      } else {
        callback();
      }
    },
    validateUserName(rule, value, callback) {

      // 先调用 existUserName 方法来验证用户名是否存在
      this.existUserName().then(() => {
        if (this.userExist) {
          callback(new Error('该用户存在请重新输入'))
        } else {
          callback();
        }
      });


    }
    ,
    // 验证确认密码是否和第一次输入的密码相同
    validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请确认密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },
    // 验证用户名是否已经存在
    existUserName() {

      return new Promise((resolve, reject) => {
        // 发出 Ajax 请求
        request.get('/data/user/validateUser/' + this.form.username).then(result => {
          console.log("========existUserName", this.userExist)
          if (result.code === 200) {
            this.userExist = true;
          } else if (result.code === 404) {
            this.userExist = false;
          }
          resolve(); // 请求完成后，调用 resolve 方法
        }).catch(error => {
          console.error('Error checking username:', error);
          reject(); // 请求出错时，调用 reject 方法
        });
      });

    },
    register() {
      this.$refs['form'].validate(validate => {

        if (validate) {

          request.post("/data/user/registerUser", {
              userName: this.form.username,
              password: this.form.password,
              email: this.form.email
          }).then(result => {

            if (result.code === 200) {

              //注册成功则跳转到登录界面
              router.push('/login');

            } else if (result.code === 404) {
              this.$message({
                type: "error",
                message: result.message
              })
            }

          })

        }

      });
    }
  },
  mounted() {
    document.body.style.backgroundImage = this.imgPath;
  },
  beforeUnmount() {
    document.body.style.backgroundImage = '';
  }
}

</script>


<style>

.register-form {
  height: 400px;
  width: 400px;
  margin: 0 auto;
  border-radius: 10%;
  background-color: #ecf5ff;

  opacity: 0.7;
  position: relative;
}

.register-header {
  width: 300px;
  margin: 0 auto;
  margin-top: 5%;
  padding: 10px;
  text-align: center;
}

.register-header span {
  font-size: 45px;
  font-family: SimSun;
  font-weight: bold;
  color: #031B4E;
}


</style>