<!--
 @Author: leon--ZGJ
 @Create: 2024/6/8 16:28
 @Version: 1.0
 Description: 用来添加图书的
-->
<template>
  <div class="body-right-view" style="flex: 9;margin-top: 60px">
    <el-form
        ref="book"
        :model="tableData"
        :rules="rules"
        class="demo-ruleForm"
    >
      <el-table :data="[tableData]" class="custom-table" style="width: 1000px">
        <el-table-column label="图片" width="150">
          <template #default="scope">
            <el-image style="width: 60px; height: 60px" :src="imgPath"/>
          </template>
        </el-table-column>
        <el-table-column label="书名" width="150">
          <el-form-item prop="bookName" style="margin-left: 15px ; margin-top: 15px;width: 100px">
            <el-input v-model="tableData.bookName" placeholder="请输入书名"/>
          </el-form-item>
        </el-table-column>
        <el-table-column label="作者" width="150">
          <el-form-item prop="author" style="margin-left: 15px ;margin-top: 15px;width: 100px">
            <el-input v-model="tableData.author" placeholder="请输入作者"/>
          </el-form-item>
        </el-table-column>
        <el-table-column label="科类" width="150">
          <el-form-item prop="category" style="margin-left: 15px ;margin-top: 15px;width: 100px">
            <el-input v-model="tableData.category" placeholder="请输入科类"/>
          </el-form-item>
        </el-table-column>
        <el-table-column label="借阅" width="150">
          <el-form-item prop="borrowNumber" style="margin-left: 15px ;margin-top: 15px;width: 100px">
            <el-input v-model="tableData.borrowNumber" placeholder="请输入借阅"/>
          </el-form-item>
        </el-table-column>
        <el-table-column label="库存" width="150">
          <el-form-item prop="stock" style="margin-left: 15px ;margin-top: 15px;width: 100px">
            <el-input clearable v-model="tableData.stock" placeholder="请输入库存"/>
          </el-form-item>
        </el-table-column>

        <el-table-column label="操作">
          <template #default="scope">
            <el-button style="text-decoration: none;color: #FFFFFF;margin-bottom: 6px"
                       size="small"
                       type="danger"
                       @click="insertBook"
            >
              添加
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import {ElMessageBox} from "element-plus";
import request from "@/utils/request";
import router from "@/router";

export default {
  name: 'manageInsertBookTable',
  data() {
    return {
      imgPath: require('@/assets/default.jpg'),
      tableData: {
        bookName: '',
        author: '',
        category: '',
        borrowNumber: '',
        stock: '',
      },
      rules: {
        bookName: [{
          required: true, message: "请输入书籍名称", trigger: 'blur'
        }],
        author: [{
          required: true, message: "请输入书籍作者", trigger: 'blur'
        }],
        category: [{
          required: true, message: "请输入书籍科类", trigger: 'blur'
        }],
        borrowNumber: [{
          required: true, message: "请输入借阅数", trigger: 'blur'
        }, {
          ///^(0|[1-9]\d*)$/
          pattern: /^(0|[1-9]\d*)$/,
          message: "数值为 >= 0的数",
          trigger: 'blur'
        }],
        stock: [{
          required: true, message: "请输入书籍库存", trigger: 'blur'
        }, {
          ///^(1|[1-9]\d*)$/
          pattern: /^(1|[1-9]\d*)$/,
          message: "数值为 >= 1的数",
          trigger: 'blur'
        }]
      },
    }
  },
  methods: {
    insertBook() {
      ElMessageBox.confirm(
          '',
          {
            title: '添加',
            message: '您确认是需要添加吗？',
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            draggable: true,
            center: true,
            icon: 'EditPen',
            customStyle: {width: "200px",}
          }
      ).then(() => {

        this.$refs['book'].validate(validate => {

          if (validate) {

            console.log(this.tableData)

            request.post("/data/book/insertBook", this.tableData).then(result => {

              if (result && result.code === 200) {

                this.tableData = {};

                this.$message({
                  type: "success",
                  message: result.message
                })

                router.push({name:'AdministratorBookDisplay'});

              } else if (result && result.code === 404) {
                this.$message({
                  type: "error",
                  message: result.message,
                })

                this.tableData ={};

              }


            }).catch(error => {

              this.$message({
                type: "error",
                message: error
              })


            })

          }

        })


      }).catch(() => {
      })

    }
  }
}
</script>

<style>
.custom-table .el-table__header th .cell,
.custom-table .el-table__body td .cell {
  text-align: center;
}
</style>